.twitter {
  background-image: url(../../assets/icons/twitter-default.svg);
}
.twitter:hover {
  background-image: url(../../assets/icons/twitter-active.svg);
}
.discord {
  background-image: url(../../assets/icons/discord-default.svg);
}
.discord:hover {
  background-image: url(../../assets/icons/discord-active.svg);
}
.telegram {
  background-image: url(../../assets/icons/telegram-default.svg);
}
.telegram:hover {
  background-image: url(../../assets/icons/telegram-active.svg);
}
.desktop {
  background-image: url(../../assets/icons/desktop-default.svg);
}
.desktop:hover {
  background-image: url(../../assets/icons/desktop-active.svg);
}
