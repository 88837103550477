input {
  border: none;
}
input:focus {
  outline: none;
}
.borderShadow{
  box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04);
}
.ant-picker-suffix{
  display: none !important;
}
.ant-picker-input{
  padding-left: 32px;
}