@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "PoppinsBold";
  src: url("./assets/fonts/Poppins-Bold.woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "PoppinsNormal";
  src: url("./assets/fonts/Poppins-Regular.woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "PoppinsSemiBold";
  src: url("./assets/fonts/Poppins-SemiBold.woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DIN-Condensed";
  src: url("./assets/fonts/DIN-Condensed.woff2");
  font-weight: normal;
  font-style: normal;
}
html {
  font-family: PoppinsNormal;
}
.highlight-color-none {
  -webkit-tap-highlight-color: transparent;
}
.bg-dashed {
  background-image: linear-gradient(to right, rgba(212, 216, 224, 0.3) 50%, rgba(212, 216, 224, 0.3) 50%, transparent 50%);
  background-size: 10px 1px;
}
.dark .bg-dashed {
  background-image: linear-gradient(to right, rgba(53, 71, 87, 0.3) 50%, rgba(53, 71, 87, 0.3) 50%, transparent 50%);
  background-size: 10px 1px;
}
.h-header {
  height: calc(100vh - 48px);
}
