@font-face {
  font-family: "icon";
  src: url('icon.eot?t=1680169090943'); /* IE9*/
  src: url('icon.eot?t=1680169090943#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url("icon.woff2?t=1680169090943") format("woff2"),
  url("icon.woff?t=1680169090943") format("woff"),
  url('icon.ttf?t=1680169090943') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  url('icon.svg?t=1680169090943#icon') format('svg'); /* iOS 4.1- */
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icon' !important;
  font-size:18px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-calendar:before { content: "\ea01"; }
.icon-close-gray:before { content: "\ea02"; }
.icon-close:before { content: "\ea03"; }
.icon-desktop-active:before { content: "\ea04"; }
.icon-desktop-default:before { content: "\ea05"; }
.icon-discord-active:before { content: "\ea06"; }
.icon-discord-default:before { content: "\ea07"; }
.icon-discord:before { content: "\ea08"; }
.icon-done:before { content: "\ea09"; }
.icon-down:before { content: "\ea0a"; }
.icon-error:before { content: "\ea0b"; }
.icon-fail:before { content: "\ea0c"; }
.icon-happy:before { content: "\ea0d"; }
.icon-loading:before { content: "\ea0e"; }
.icon-lock:before { content: "\ea0f"; }
.icon-mac:before { content: "\ea10"; }
.icon-nothappy:before { content: "\ea11"; }
.icon-notjoin:before { content: "\ea12"; }
.icon-refresh-hover:before { content: "\ea13"; }
.icon-refresh:before { content: "\ea14"; }
.icon-search:before { content: "\ea15"; }
.icon-search_gray:before { content: "\ea16"; }
.icon-success:before { content: "\ea17"; }
.icon-success_white:before { content: "\ea18"; }
.icon-telegram-active:before { content: "\ea19"; }
.icon-telegram-default:before { content: "\ea1a"; }
.icon-telegram:before { content: "\ea1b"; }
.icon-time:before { content: "\ea1c"; }
.icon-triangle:before { content: "\ea1d"; }
.icon-twitter-active:before { content: "\ea1e"; }
.icon-twitter-default:before { content: "\ea1f"; }
.icon-twitter:before { content: "\ea20"; }
.icon-up:before { content: "\ea21"; }
.icon-wallet:before { content: "\ea22"; }
.icon-wallet_white:before { content: "\ea23"; }
.icon-windows:before { content: "\ea24"; }
